ul,ol{margin:0;padding: 0;}
a:focus, a:hover{text-decoration: none;}
body{font-family: 'Lato', sans-serif;color: #333;}
.navbar-brand{background: url(../images/logo.png) no-repeat 0 0;width: 270px;height: 45px;overflow: hidden;text-indent: 300%;}
.navbar.navbar-default{background: none transparent;border-color: transparent;padding: 15px 0;}
.navbar-nav>li{margin-left: 10px;margin-right: 10px;margin-top: 10px;}
.navbar-default .navbar-nav>li>a{color: #fff;font-size: 16px;padding: 10px 20px;}
header.inverse .navbar-default .navbar-nav>li>a{color: #303030;}
header.inverse .navbar-nav>li>a.btn.btn-blue{color: #fff}
header.inverse {border-bottom: 1px solid #c9c9c9;}
header.inverse .navbar.navbar-default{margin-bottom: 0;}
header.inverse .navbar-brand{background: url(../images/logo-inverse.png) no-repeat 0 0;}
.navbar-default .navbar-nav>li>a:hover {color: #ddd;}
.navbar-nav>li>a.btn.btn-blue{background-color: #3498db;}
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover{ color: #fff; background-color: #3498db;border-radius: 4px 4px 0 0}
.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover{color: #fff; text-decoration: none; background-color: #3498db;}
.dropdown-menu>li>a{padding: 10px 20px;}
.dropdown-menu{padding: 0; border: 0;}
.mrg-rght-no{margin-top: 0;}
.mrg-rght-sm{margin-right: 20px;}

.main-sec h2{font-size: 33px;margin-top: 45px;}
.Uppercase{text-transform: uppercase;}
.main-sec h4{font-size: 20px;margin-top: 5px;}
.main-sec .sub-head{font-size: 18px;display: block;margin-bottom: 45px;}
small.sub-head.text-center.red {color: #e74c3c;}
.signup-form{background: url(../images/sign-up-bg.jpg) repeat 0 0;padding-bottom: 25px;margin-bottom: 30px;}
.signup-form h2{color: #fff;}
.signup-form form{margin-top: 30px;}
.signup-form form .input-group{width: 210px;margin-right: 14px;margin-bottom: 10px;}
.signup-form form .input-group-addon{border: 0;background-color: #3498db;border-radius: 2px 0 0 2px;}
.signup-form form .input-group-addon .glyphicon{color: #fff;font-size: 18px;}
.signup-form form input.form-control{height: 45px;font-size: 14px;color: #22272a;border-radius: 0 2px 2px 0;border: 0;box-shadow: none;-webkit-box-shadow: none;}
.signup-form form .input-group button{width: 100%;border-radius: 2px;color: #fff;font-size: 18px;background-color: #3498db;height: 45px;}

.sub-footer{margin-top: 30px;padding-bottom: 30px;}
.sub-footer .title{margin-bottom: 20px;font-size: 15px;display: block;line-height: 27px;font-weight: bold;}
.sub-footer .title > img.logo-f{max-width: 165px;height: auto;}
.sub-footer .list{padding-left: 0;list-style: none;}
.sub-footer .list li{background: url(../images/list-icn.png) no-repeat 0 7px;padding-left: 17px;margin-bottom: 5px;}
.sub-footer .list li a{color: #333;}
.sub-footer .contact-detail p{margin-bottom: 2px;}
.sub-footer .newsletter input{box-shadow:none;-webkit-box-shadow:none;background-color: #f4f4f4;height: 35px;border-left: 0;padding: 0 10px 0 0;border-color: #bdbebd;color: #333;font-size: 14px;}
.sub-footer .newsletter input:focus{outline: 0;}
.sub-footer .newsletter .input-group-addon{padding: 0 10px;background-color: #f4f4f4;line-height: 1;font-size: 14px;border-color: #bdbebd;padding-top: 1px;}
.sub-footer .newsletter .input-group-addon .glyphicon{color: #777;}
.sub-footer .newsletter .input-group-addon.subscribe{background-color: #3498db;padding: 0;width: 85px;}
.sub-footer .newsletter .input-group-addon.subscribe button{width: 100%;background-color: #3498db;height: 32px;border: 0;color: #fff;font-size: 14px;}
.no-clr,.no-clr:hover{color: inherit;}
footer{clear: both;}
.footer{padding: 25px 0 5px 0;border-top: 1px solid #000; text-align: center;}
.footer .list{list-style: none;}
.footer .list li{padding-right: 15px;padding-left: 15px;}
/*Contact Popup*/
.contact-popup {position: relative;z-index: 999999;}
.contact-popup .modal-header{border: 0;}
.contact-popup .modal-header .close{opacity: 1;}
.contact-popup .modal-header h4{text-align: center;font-size: 22px;color: #000;}
.contact-popup .modal-dialog {width: 500px;max-width: 100%;}
.contact-popup .modal-content{border-radius: 0;}
.contact-popup .input-group{position: relative;width: 100%;max-width: 310px;margin: 0 auto 20px auto;float: none;}
.contact-popup .form-control{background-color: #f7f7f7;line-height: 50px;height: 50px;border: 0;border-radius: 0;box-shadow: none;width: 100%;padding-left: 48px;font-size: 14px;color: #000;}
.contact-popup .input-group-addon{background-color: transparent;border: 0;position: absolute;top: 10px;z-index: 22;left: 6px;}
.contact-popup .input-group-addon i.fa{font-size: 18px;color: #000;}
.contact-popup .phone .input-group-addon{left: 10px;}
.contact-popup .phone .input-group-addon i.fa{font-size: 22px;}
.contact-popup textarea.form-control{height: 140px;line-height: 1;display: block;text-align: left;padding-top: 18px;}
.contact-popup .btn.btn-big{background-color: #3897d9;color: #fff;text-transform: uppercase;line-height: 50px;width: 175px;padding: 0;border-radius: 0;font-size: 18px;}
.contact-popup .success-message{ color: green; text-align: center; font-size: 18px; padding-bottom: 30px;}
/*Contact Popup*/
/*Home*/
.page-header{color: #fff !important;font-size: 16px;border-bottom: 0;margin-bottom: 0;padding-bottom: 0;}
.page-header *{color:#fff;}
.home .navbar-brand{background-image: url(../images/logo.png);}
.home header { background: transparent none repeat scroll 0 0; left: 0; position: absolute; right: 0; top: 0; z-index: 99999;}
.home > .bx-wrapper{position: relative;}
.page-header h1{font-size: 34px;line-height: 48px;margin-bottom: 15px;font-weight: 300;}
.page-header h1 strong{font-weight: 500;text-transform: uppercase;}
.page-header h1 span{display: block;}
.page-header .lead{margin-bottom: 30px}
.page-header select.form-control{padding: 0 35px 0 12px;font-size: 16px;background-color: transparent;-webkit-appearance:none;-moz-appearance:none;-o-appearance:none;appearance:none;color: #fff;}
.page-header select.form-control option{color: #000;}
.page-header select.form-control::ms-expand{display: none;}
.page-header a.btn.btn-default{color: #3498db;font-size: 16px;border-radius: 25px;line-height: 40px;padding: 0 35px;text-transform: uppercase;}
.page-header a.btn.btn-default.no-brd-rds{border-radius: 0;}
.page-header a.link{text-transform: uppercase;color: #fff;padding-left: 50px;line-height: 41px;background: url(../images/play-icn.png) left center no-repeat;display: inline-block;}
.page-header .header-img{margin-top: -35px;}
.page-header form big{font-size: 45px;opacity: 0.3;line-height: normal;}
.page-header form p.static{opacity: 0.3;font-size: 15px;padding-bottom: 0;}
.page-header .form-group {margin-bottom: 20px;}
.page-header .container{margin: 95px auto 0 auto;min-height: 410px;}
.select-block{position: relative;display: inline-block;}
.select-block i{position: absolute;top: 50%;right: 11px;margin-top: -4px;width: 14px;height: 9px;}
.select-block i:before{content: "\e252";font-family: 'Glyphicons Halflings';font-style: normal;font-weight: 400;line-height: 1;font-size: 13px;position: absolute;}
.main-sec.home ul li .banner{background: url(../images/banner-1.jpg) no-repeat center center;margin-top: 0;padding-top: 109px;background-size: cover;}
.main-sec.home ul li .banner.banner-2{background-image: url(../images/banner-2.jpg);}
.banner.banner-2 .page-header h1{text-transform: uppercase;}
.main-sec.home ul li .banner.banner-3{background-image: url(../images/banner-3.jpg);}
.banner.banner-4 .page-header h1 strong,.banner.banner-3 .page-header h1 strong{text-transform: none;}
.main-sec.home ul li .banner.banner-4{background-image: url(../images/banner-4.jpg);}
.main-sec.home ul li .banner.banner-5{background-image: url(../images/banner-5.jpg);}
.services{padding: 26px 0 15px 0;}
.services .list-inline li{width: 80px;margin: 0 28px 15px 28px;background: url(../images/icn-sprite.png) no-repeat 0 0;padding-top: 58px;}
.services .list-inline li.price{background-position: 14px 0;}
.services .list-inline li.shipping{background-position: -129px 0;}
.services .list-inline li.targetting{background-position: -271px 0;}
.services .list-inline li.cart{background-position: -420px 0;}
.services .list-inline li.sell{background-position: -560px 0;}
.services .list-inline li.stock{background-position: -703px 0;}
.services .list-inline li.transaction{background-position: -846px 0;}
.services .list-inline li.promotional{background-position: -990px 0;}
.services{background-color: #f9f9f9;}

.feauter-block .head-text{border-bottom: 3px solid #3498db;position: relative;cursor: pointer;}
.feauter-block .head-text .title{position: relative;font-weight: bold;padding: 15px 40px 13px 40px;line-height: 25px;padding-bottom: 13px;display: inline-block;cursor: pointer;}
.feauter-block .active .head-text .title{background-color: #3498db;color: #fff;text-decoration: none;}
.feauter-block .active .head-text .title:before,
.feauter-block .active .head-text .title:after{content: '';position: absolute;border-right: 15px solid #3498db;border-top: 54px solid transparent;left: -15px;top: 0;}
.feauter-block .active .head-text .title:after{border-top: 54px solid #3498db;border-right: 15px solid transparent;left: inherit;right: -15px;}
.feauter-block .active .head-text .title i{color: #333;background-color: #fff;}
.feauter-block .head-text .title i{font-style: normal;position: absolute;left: 0;top: 50%;height: 25px;width: 25px;line-height: 25px;text-align: center;display: block;color: #fff;background-color: #3498db;border-radius: 50%;margin-top: -12px;}
.feauter-block .col-sm-4 .head-text:before,
.feauter-block .col-sm-4 .head-text:after{content: '';height: 3px;width: 15px;background-color: #3498db;display: block;position: absolute;bottom: -3px;left: -15px;}
.feauter-block .col-sm-4 .head-text:after{left: inherit;right: -15px;}
.feauter-block .col-sm-4 p{line-height: 21px;margin-top: 13px;}

.analytics-block{background: url(../images/analytics-bg.png) no-repeat center 30px;margin-top: 65px;margin-bottom: 60px;}
.analytics-block .mac{background: url(../images/mac.png) no-repeat 0 0;display: block;margin: 0 auto;width: 825px;}
.analytics-block .mac .mac-inner{width: 606px;margin: 0 auto;}
.analytics-block img{margin:31px 0 60px 0;border-radius: 5px;}

.logo-list{margin-top: 35px;margin-bottom: 35px;}
.logo-list li{margin:0 0 0 60px;}
.logo-list li .img-hide{display: none;}
.logo-list li:hover .img-hide{display: inline-block;}
.logo-list li:hover .img-show{display: none;}

.stats-block-main{position: relative;}
.stats-block-main .container{box-shadow: -760px 0 #f9f9f9,760px 0 #3498db;-moz-box-shadow: -760px 0 #f9f9f9,760px 0 #3498db;-webkit-box-shadow: -760px 0 #f9f9f9,760px 0 #3498db;}
.stats-block-main .stats-block{background-color: #f9f9f9;padding-top: 30px;padding-bottom: 20px;box-shadow: -50px 0 #f9f9f9;-moz-box-shadow: -50px 0 #f9f9f9;-webkit-box-shadow: -50px 0 #f9f9f9;}
.stats-block-main .stats{display: table;margin:0 auto 20px auto;position: relative;text-align: center;}
.stats-block-main .stats:before{background: url(../images/stats-icn.png) no-repeat;width: 56px;height: 58px;content: '';display: block;margin: 0 auto;}
.stats-block-main .stats.sents:before{background-position: 4px 0;}
.stats-block-main .stats.deliveries:before{background-position: -91px 0px;}
.stats-block-main .stats.clicks:before{background-position: 0px -144px;}
.stats-block-main .stats.converts:before{background-position: -89px -149px;}
.stats-block-main .stats big{display: block;font-size: 36px;line-height: 45px;}
.stats-block-main .stats small{display: block;font-size: 15px;}
.testimonial{background-color: #3498db;box-shadow: 20px 0 #3498db;-moz-box-shadow: 20px 0 #3498db;-webkit-box-shadow: 20px 0 #3498db;}
.testimonial:before{content: '';border-right: 215px solid #3498db;border-top: 338px solid transparent;position: absolute;left: -215px;}
.testimonial:after{content: '';position: relative;position: absolute;top: 0;left: 0;width: 134px;height: 111px;}

.testimonial .slider{border-radius: 5px;margin-top: 60px;width: 500px;margin-bottom: 78px;float: right;position: relative;}
.testimonial .slider p{color: #fff;font-size: 14px;}
.testimonial .slider .carousel {height: 200px;margin-bottom: 0;}
.testimonial .slider .carousel-caption {z-index: 10;text-shadow:none;right: 0;left: 24%;border: 1px solid #fff;padding-left: 38px;padding-right: 38px;padding-bottom: 50px;padding-top: 35px;bottom: 0px;border-radius: 5px;}
.testimonial .slider .carousel-caption:before{content: '';width: 14px;height: 22px;background: url(../images/tool-tip-icn.jpg) no-repeat 0 0;position: absolute;top: 50%;margin-top: -11px;left: -14px;}
.testimonial .slider .carousel-caption img{position: absolute;left: -121px;z-index: 1;transform: translateY(-50%);-moz-transform: translateY(-50%);-webkit-transform: translateY(-50%);top: 50%;max-width: 91px;height: auto;}
.testimonial .slider .carousel-caption .name{font-size: 18px;color: #fff;font-weight: bold;}
.testimonial .slider .carousel-caption .name .des{font-size: 14px;color: #fff;font-weight: normal;}
.testimonial .slider .carousel .item {height: 200px;}
.testimonial .slider .carousel-indicators {bottom: 8px;right: 35px;left: inherit;}
.testimonial .slider .carousel-indicators li {display: inline-block;width: 12px;height: 12px;border-width: 2px;}
.testimonial .slider .carousel-indicators li.active{margin: 1px;width: 12px;height: 12px;}

.blog-main{margin-bottom: 35px;display: inline-block;width: 100%;}
.blog-main .post-block{margin-bottom: 35px;border: 1px solid #b8b8b8;}
.blog-main .post-block > div{background-color: #fff;padding: 0;}
.blog-main .post-block > .first{overflow: hidden; max-height:272px;}
.blog-main .post-block > .first img {width: 100%;}
.blog-main .post-block > .second{padding: 0 25px 10px 25px;}
.blog-main .post-block > .second i.shape{content: '';border-right: 50px solid #fff;border-bottom: 272px solid transparent;position: absolute;left: -50px;}
.blog-main .post-block.odd > .second i.shape{content: '';border-left: 50px solid #fff;border-right: 0;border-bottom: 272px solid transparent;position: absolute;right: -50px;left: inherit;}
.blog-main .head-block{padding:25px 0 0 0;display: inline-block;width: 100%;}
.blog-main .head-block > img{margin-right: 20px;float: left;margin-bottom: 25px;max-width: 60px;}
.blog-main .head-block .title-b{width: calc(100% - 85px);float: left;margin-bottom: 25px;padding-top: 10px;}
.blog-main .head-block .title-b span.title{display: block;font-size: 18px;font-weight: bold;}
.blog-main .head-block .title-b span.name{display: block;font-size: 16px;}
.blog-main .blog-counter{width: 100%;float: left;}
.blog-main .blog-counter span{font-size: 13px;color: #999;position: relative;padding-right: 10px;margin-right: 4px;}
.blog-main .blog-counter span i{margin-right: 8px;font-style: normal;font-size: 14px;}
.blog-main .blog-counter span.comments i{background: url(../images/comment.png) no-repeat;width: 14px;height: 11px;display: inline-block;}
.blog-main .blog-counter span:last-child:after{display: none;}
.blog-main .blog-counter span:after{display: inline-block;content: '/';position: absolute;right: 0;}
.blog-main p.text{float: left;color: #777;line-height: 25px;}
.blog-main a.read-more{float: right;font-style: italic;color: #3498db;}
/*Home*/

/*Feature Page*/
.f-navigation{background-color: #f4f4f4}
.f-navigation ul.nav-tabs li a{font-size: 15px;border: 0;border-bottom: 5px solid transparent;background-color: transparent;color: #333;border-radius: 0;}
.f-navigation ul.nav-tabs li.active a,
.f-navigation ul.nav-tabs li a:hover{ border-bottom: 5px solid #3498db!important; border-width: 0;}

.pricing-block-main{margin-bottom: 20px;padding: 65px 0;}
.pricing-block-main img.pricing-image{position: absolute;left: -80px;}
.pricing-block-main.even{background-color: #fbfbfc;}
.pricing-block-main img.pricing-image{width: 100%;}
.pricing-block-main .heading-block{position: relative;margin-bottom: 45px;}
.pricing-block-main .heading-block i{position: absolute;left: 0;top: -25px;background: url(../images/piggibank-icn.png) no-repeat 0 0;height: 70px;width: 70px;}
.pricing-block-main h3{font-size: 24px;}
ul.dot.list{padding-left: 0;}
.pricing-block-main hr{width: 100px;border: 0;background-color: #eeeeee;height: 1px;display: inline-block;}
ul.dot.list li{position: relative;padding-left: 25px;list-style: none;}
ul.dot.list li:before{content:'';height: 10px;width: 10px;background-color: #3498db;display: block;border-radius: 50%;position: absolute;left: 0;top: 5px;}
.feature-list .icon{display: block;background: url(../images/feature-icons.png) no-repeat 0 0;width: 80px;height: 80px;}
.feature-list p{line-height: 22px;}
.feature-list .icon-1{background-position: 0 0;}
.feature-list .icon-2{background-position: -101px 0;}
.feature-list .icon-3{background-position: -208px 0;}
.feature-list .icon-4{background-position: -299px 0;}
.feature-list .icon-5{background-position: 3px -75px;}
.feature-list .icon-6{background-position: -101px -77px;}
.feature-list .icon-7{background-position: -208px -85px;}
.feature-list .icon-8{background-position: -294px -78px;}

/*Feature Page*/

/*Affiliate*/
.affiliate-head{margin: 30px 0 25px 0;}
.affiliate-head .icon{border-bottom: 2px solid #3498db;margin-bottom: 18px;}
.affiliate-head .icon i{width: 98px;height: 100px;background: url(../images/affiliate-icons.png) no-repeat #3498db 30px 13px;display: block;}
.affiliate-head .icon.icon-2 i{background-position: -350px 18px;}
.affiliate-head .icon.icon-3 i{background-position: -740px 13px;}
.affiliate-head h3{color: #444;font-size: 17px;margin-top: 0;font-weight: bold;text-transform: uppercase;}
.affiliate-head p{font-size: 15px;line-height: 27px;color: #666;margin-bottom: 25px;}
.affiliate-program {background: url(../images/affiliate-background.jpg) repeat;}
.affiliate-program h2{color: #fff;margin: 60px 0 35px 0;}
.affiliate-program .title{font-size: 18px;color: #fff;font-weight: bold;}
.affiliate-program p{color: #fff;line-height: 20px;}
.affiliate-block{margin-bottom: 50px;}
/*Affiliate*/

/*plan and Pricing*/
.pricing-main h2{font-weight: bold;}
.pricing-main .note{color: #3498db;text-align: center;}
.c_pricing-switcher {text-align: center;}
.c_pricing-switcher .fieldset {display: inline-block;position: relative;border-radius: 25px;border: 1px solid #3498db;}
.c_pricing-switcher input[type="radio"] {position: absolute;opacity: 0;}
.c_pricing-switcher label {position: relative;z-index: 1;display: inline-block;float: left;width: 90px;height: 28px;line-height: 28px;cursor: pointer;font-size: 12px;color: #333;text-transform: uppercase;margin-bottom: 0;transition: color 0.3s ease;-webkit-transition: color 0.3s ease;-moz-transition: color 0.3s ease;}
.c_pricing-switcher .c_switch {position: absolute;top: 0;left: 0;height: 28px;width: 90px;background-color: #3498db;border-radius: 50em;-webkit-transition: -webkit-transform 0.5s;-moz-transition: -moz-transform 0.5s;transition: transform 0.5s;}
.c_pricing-switcher input[type="radio"]:checked + label + .c_switch,
.c_pricing-switcher input[type="radio"]:checked + label:nth-of-type(n) + .c_switch {-webkit-transform: translateX(90px);-moz-transform: translateX(90px);-ms-transform: translateX(90px);-o-transform: translateX(90px);transform: translateX(90px);}
.c_pricing-switcher input[type="radio"]:checked + label{color: #fff;}
.pricing-graph{margin-bottom: 30px;display: inline-block;width: 100%;position: relative;padding-left: 4px;box-sizing: border-box;}
.c_pricing-block{padding: 20px 20px 15px 20px;width: 20%;box-sizing:border-box;background-color: #70b6f3;display: inline-block;text-align: center;margin-left: -4px;}
.c_pricing-block *{color: #fff;display: inline-block;clear: both;}
.c_pricing-block	h4{font-size: 22px;color: #fff;text-align: center;text-transform: uppercase;margin-bottom: 40px;display: block;}
.c_pricing-block .price{border-bottom: 1px solid #dddddd;padding-bottom: 25px;margin-bottom: 20px;}
.c_pricing-block .c_currency{font-weight: 300;font-size: 44px;}
.old-price { font-size: 24px;font-weight: 300;text-decoration: line-through;}
.c_pricing-block .c_value{font-size: 44px;line-height: 64px;font-weight: 300;}
.c_pricing-block .c_duration{font-size: 16px;font-weight: 300;vertical-align: bottom;margin-left: -15px;}
.c_pricing-block ul{padding-left: 0;margin-bottom: 45px;}
.c_pricing-block ul li{display: block;margin-bottom: 10px;}
.c_pricing-block .button{background-color: #fff;color: #3498db;font-size: 14px;text-align: center;display: block;line-height: 48px;text-decoration: none;}
.c_pricing-block.pricing_1{background-color: #53a8f1;}
.c_pricing-block.pricing_2{background-color: #399bf0;}
.c_pricing-block.pricing_3{background-color: #328de2;}
.c_pricing-block.pricing_4{background-color: #287ccf;}
p.no-card { font-style: italic; font-weight: 300;}
.pricing-main .content-block{padding-left: 38px;position: relative;}
.pricing-main .content-block .glyphicon{color: #3498db;position: absolute;left: 0;top: 0;font-size: 27px;}
.pricing-main .content-block .title{font-size: 18px;font-weight: bold;}
.pricing-main .content-block p{line-height: 20px;}
.pricing-main .content-block{margin-bottom: 50px;}
.pricing-main .axis-block .x-axis {width: 110%;height: 1px;background-color: #3498db;left: -5%;position: absolute;bottom: 0;display: block;}
.pricing-main .axis-block .x-axis:before{position: absolute;content: '';border-left: 7px solid #3498db;border-top: 7px solid transparent;border-bottom: 7px solid transparent;right: 0;top: -7px;}
.pricing-main .axis-block .y-axis {height: 110%;width: 1px;background-color: #3498db;left: -1px;position: absolute;bottom: -5%;display: block;}
.pricing-main .axis-block .y-axis:before{position: absolute;content: '';border-left: 7px solid transparent;border-right: 7px solid transparent;border-bottom: 7px solid #3498db;right: -7px;top: 0;}
/*plan and Pricing*/

/*register*/
.register-popup .modal-content{border-radius: 2px;}
.register-popup .modal-header{background-color: #3498db;}
.register-popup .modal-header h4{font-size: 30px;color: #fff;text-align: center;padding: 6px 0;}
.register-popup .modal-dialog {width: 750px;max-width: 100%;}
.register-popup .modal-header .close{opacity: 1;}
.register-popup .modal-header .close span{position: absolute;top: -15px;right: -15px;}
.register-popup .modal-header .close span:before{content: "\e083";font-family: 'Glyphicons Halflings';font-style: normal;font-weight: 400;line-height: 1;font-size: 30px;color: #fff;}
.register-popup .title{font-size: 23px;color: #444;margin-bottom: 22px;display: block;}
.register-popup .modal-footer .title{font-size: 18px;text-align: center;margin-bottom: 14px;}
.register-popup .modal-body{padding-left: 20px;display: table;width: 100%;padding=r: -r;padding-right: 20px;}
.register-popup .left-block{padding-left: 25px;width: 45%;padding-right: 5%;float: none;border-bottom: 1px solid #eeeeee;display: table-cell;position: relative;}
.register-popup .left-block:before{height: 1px;background-color: #fff;width: 32px;right: -1px;bottom: -1px;display: block;content: '';position: absolute;}
.register-popup .right-block:before{height: 1px;background-color: #fff;width: 32px;left: -1px;bottom: -1px;display: block;content: '';position: absolute;}
.register-popup .right-block:after{height: 58px;background-color: #fff;width: 1px;left: -1px;bottom: 0;display: block;content: '';position: absolute;}
.register-popup .right-block{width: 50%;padding-left: 5%;float: none;border-left: 1px solid #eeeeee;border-bottom: 1px solid #eeeeee;display: table-cell;position: relative;}
.register-popup p.text{font-size: 16px;color: #444;margin: 0;text-align: left;margin-bottom: 5px;}
.register-popup p.text a{color: #3498db;}
.register-popup form .input-group{width: 85%;margin-right: 14px;margin-bottom: 14px;}
.register-popup form .input-group-addon{border: 0;background-color: #3498db;border-radius: 2px 0 0 2px;}
.register-popup form .input-group-addon .glyphicon{color: #fff;font-size: 18px;}
.register-popup form input.form-control{height: 45px;font-size: 14px;color: #22272a;border-radius: 0 2px 2px 0;border: 0;box-shadow: none;-webkit-box-shadow: none;}
.register-popup form .input-group button{border-radius: 2px;color: #fff;font-size: 18px;background-color: #3498db;height: 36px;float: left;    margin-top: 5px;}
.register-popup form input.form-control{background-color: #f7f7f7;}
.register-popup .modal-footer{text-align: center;padding-bottom: 30px;border: 0;padding-top: 10px;}
.register-popup .modal-footer .btn{font-size: 14px;text-transform: uppercase;border: 0;line-height: 37px;padding: 0 20px;}
.register-popup .facebook{background-color: #3b5998;}
.register-popup .twitter{background-color: #00acee;}
.register-popup .list{padding-left: 0;}
.register-popup .list li{color: #303030;font-size: 18px;list-style: none;padding-left: 30px;line-height: 22px;margin-bottom: 15px;position: relative;}
.register-popup .list li:before{width: 24px;height: 24px;content: '';position: absolute;top: 0;left: 0;background: url(../images/register-icon.png) no-repeat 0 0;}
.register-popup .list li.icon-2:before{background-position: 0 -32px;}
.register-popup .list li.icon-3:before{background-position: 2px -67px;}
.register-popup .list li.icon-4:before{background-position: 0 -100px;}
.register-popup .list li.icon-5:before{background-position: 0 -134px;}
.register-popup .list li.icon-6:before{background-position: 0 -168px;}
.register-popup .list li.icon-7:before{background-position: 1px -204px;}
.register-popup .list li.icon-8:before{background-position: 0 -239px;}
.register-popup .list li.icon-9:before{background-position: 0 -273px;}
/*register*/


/*About Us*/
.about-us > .banner{background: url(../images/about-us-bg.jpg) no-repeat center 0;margin-top: -109px;padding-top: 149px;background-size: cover;padding-bottom: 100px;}
.about-us h1{font-size: 34px;color: #fff;font-weight: bold;text-transform: uppercase;margin-bottom: 12px;text-align: center;}
.about-us .sub-head{font-size: 18px;color: #fff;font-weight: 300;text-align: center;}
.about-us .stats-block-main .stats-block{background-color: transparent;box-shadow: none;-moz-box-shadow: none;-webkit-box-shadow: none;}
.insight{margin-bottom: 15px;padding-top: 40px;}
.insight h3{padding-bottom: 12px;text-transform: uppercase;color: #444;font-size: 20px;border-bottom: 2px solid #3498db;margin-bottom: 18px;font-weight: bold;}
.insight h3 span{color: #3498db;}
.insight p{font-size: 15px;line-height: 27px;color: #666;}
.full-block{background-color: #3498db;display: inline-block;width: 100%;float: left;overflow: hidden;}
.full-block .row > div:first-child{padding: 90px 0;}
.full-block .row > div:last-child img{position: absolute;}
.full-block h3{font-size: 34px;color: #fff;text-transform: uppercase;margin-bottom: 10px;}
.full-block p{margin-bottom: 30px;font-size: 18px;color: #fff;}
.full-block p:last-child{margin-bottom: 0;line-height: 30px;}
.clients {padding: 45px 0;display: inline-block;width: 100%;float: left;clear: both;}
.clients h2{font-size: 26px;color: #444444;font-weight: bold;text-transform: uppercase;margin-bottom: 35px;text-align: center;margin-top: 0;}
.clients ul li{height: 180px;float: left;width: 20%;border-style: dashed;border-width: 0 2px 2px 0;border-color: #ddd;list-style: none;text-align: center;}
.clients ul li img{position: relative;top: 50%;transform: translateY(-50%);-moz-transform: translateY(-50%);-webkit-transform: translateY(-50%);max-height: 100%;width: auto;max-width: 90%;height: auto;}
.clients ul li:last-child{border-right: 0;}
.clients ul.bottom li{border-bottom: 0;}
.twitter-feed{background-color: #f9f9f9;float: left;display: inline-block;width: 100%;}
.twitter-feed #tweet{padding-top: 90px;background: url(../images/twiiter-logo.png) no-repeat center 20px;}
.twitter-feed #tweet ul{display: inline-block;width: 100%;list-style: none;padding: 0;}
.twitter-feed #tweet .interact{display: none;}
.twitter-feed #tweet li .tweet{font-size: 22px;text-align: center;line-height: 38px;width: 60%;margin-bottom: 20px;margin: 0 auto 20px auto;}
.twitter-feed #tweet li p a{color: #3498db;}
.twitter-feed #tweet li .timePosted{width: 100%;margin-bottom: 5px;text-align: center;}
.twitter-feed #tweet li .timePosted a{font-size: 12px;color: #aaaaaa;}
/*About Us*/

/*Blog*/
.blog-main{display: inline-block;width: 100%;float: left;display: inline-block;}
.blog-main .header{background-color: #f5f5f5;border-bottom: 1px solid #ececec;padding: 40px 0;margin-bottom: 55px;}
.blog-main .header .heading{display: inline-block;vertical-align: middle;width: 60%;}
.blog-main .header h1{font-size: 28px;color: #333;font-weight: 300;text-transform: uppercase;margin-top: 0;}
.blog-main .header small{font-size: 18px;color: #777;font-weight: 300;display: block;	}
.blog-main .header .breadcrumb-block{vertical-align: middle;}
.blog-main .header .breadcrumb-block ul{padding: 0;list-style: none;}
.blog-main .header .breadcrumb-block ul li{font-size: 12px;}
.blog-main .header .breadcrumb-block ul li a{color: #555555;}
.post-main{float: left;width: 100%;}
.post-main .cnv-post{margin-bottom: 45px;}
.post-main .cnv-post .post-head{width: 100%;float: left;margin-bottom: 20px;}
.post-main .cnv-post .post-user{margin-bottom: 25px;float: left;width: 100%;}
.post-main .cnv-post .user-img{width: 70px;float: left;margin-right: 18px;}
.post-main .cnv-post .user-img img{margin: 0 auto;}
.post-main .cnv-post .post-title{float: left;width: 84%; width: calc(100% - 95px);padding-top: 10px;}
.post-main .cnv-post .title{font-size: 24px;color: #444;font-weight: 300;margin-bottom: 5px;margin-top: 0;}
.post-main .cnv-post .published{font-size: 13px;color: #999;font-weight: 300;text-transform: uppercase;}
.post-main .post-content p{font-size: 14px;color: #555;line-height: 25px;margin-bottom: 25px;clear: both;}
.post-main .read-link{color: #3498db;text-decoration: underline;}
.post-main .read-link:hover{text-decoration: none;}
.post-main hr{clear: both;}
.post-main blockquote p{font-size: 16px;color: #555;line-height: 30px;font-weight: 300;font-style: italic;}
.post-main blockquote footer{font-size: 14px;font-weight: 300;color: #555;}
.post-main blockquote footer:before{font-weight: normal;}
.post-main .pager a{position: relative;color: #3498db;font-weight: 300;padding-left: 30px;padding-right: 18px;border-color: #aaaaaa;}
.post-main .pager a:before{content: '';background: url(../images/pager-icn.png) no-repeat 0 0;width: 10px;height: 15px;display: block;position: absolute;left: 14px;top: 50%;margin-top: -5px;}
.post-main .pager li:first-child a{float: left;}
.post-main .pager li:last-child a{float: right;}
.post-main .pager li:last-child a{padding-right: 30px;padding-left: 18px;}
.post-main .pager li:last-child a:before{left: inherit;right: 14px;background-position: -32px 0px;}
.blog-sidebar{padding-left: 25px;}
.blog-sidebar .b-title{font-size: 15px;color: #444;text-transform: uppercase;margin-bottom: 25px;display: block;}
.blog-sidebar #tweet ul{padding: 0;margin-bottom: 15px;}
.blog-sidebar #tweet li{list-style: none;color: #555;font-weight: 300;line-height: 21px;padding-left: 20px;background: url(../images/twiiter-logo-small.png) 0 6px no-repeat;}
.blog-sidebar #tweet .tweet{font-size: 14px;color: #555;line-height: 21px;margin-bottom: 0;}
.blog-sidebar #tweet .timePosted a{font-size: 11px;color: #aaa;}
.blog-sidebar #tweet .interact{display: none;}
.blog-sidebar .follow{font-size: 12px;color: #555;border:1px solid #ddd;border-radius: 2px;padding: 0 18px;line-height: 26px;float: right;}
.blog-sidebar .follow:hover{text-decoration: none;background-color: #eee;}
.blog-sidebar .side-block{float: left;padding-bottom: 45px;margin-bottom: 45px;border-bottom: 1px solid #eee;}
.blog-main .related-post ul{list-style:none;padding: 0;margin: 0;}
.blog-main .related-post li{display: table;margin-bottom: 20px;color: #333;font-size: 16px;padding: 0;}
.blog-main .related-post li > span{display: block;}
.post-main.post-inner .related-post .post-name{font-weight: bold;}
.blog-main .related-post .img-block{display: table-cell; padding-right: 15px;width: 170px;}
.blog-main .blog-sidebar .related-post .img-block{width: 86px;}
.blog-main .related-post .post-con{display: table-cell; vertical-align: middle;}
.blog-main .related-post .post-desc{opacity: 0.6;font-size: 14px;}
.blog-main .tags{border-bottom: 0;}
.blog-main .tags ul{padding: 0;}
.blog-main .tags li{display: inline-block;list-style: none;margin: 0 5px 5px 0;border: 1px solid #ccc;border-radius: 2px;}
.blog-main .tags li a{font-size: 13px;color: #444;line-height: 20px;padding: 0 7px;text-decoration: none;}
.post-main.post-inner .post-content img{margin-bottom: 30px;}
.post-main.post-inner blockquote p{font-weight: bold;}
.post-main .share{margin-bottom: 20px;}
.post-main .share a{text-decoration: none;color: #555;font-weight: bold;text-transform: capitalize;}
.post-type{float: left;width: 100%;}
.post-type hr{margin-bottom: 45px;margin-top: 45px;float: left;width: 100%;}
.post-type a{color: #3498db;font-weight: bold;display: inline-block;float: left;padding: 0 0 0 22px;position: relative; width: 47%;}
.post-type a:before{content: '';background: url(../images/post-type-arrow.png);position: absolute;left: 0;top: 25%;height: 5px;margin-top: -2px;width: 15px;display: block;}
.post-type a.next-arrow{float: right;padding: 0 22px 0 0;}
.post-type a.next-arrow:before{left: inherit;right: 0;background-position: right 0;}
.related-post {font-size: 14px;color: #777;line-height: 25px;margin-bottom: 45px;}
.comment-box{float: left;width: 100%;margin-bottom: 25px;}
.comment-box .title{font-size: 22px;color: #444;margin-bottom: 30px;display: block;}
.comment-box .title span{color: #3498db;}
.comment-box label{text-transform: uppercase;display: block;font-weight: bold;margin-bottom: 5px;color: #444;}
.comment-box input,.comment-box textarea{width: 100%;border: 2px solid #ccc;padding: 6px 10px;resize: none;outline: 0;}
.comment-box .row > div{margin-bottom: 15px;}
.comment-box input.submit{color: #fff;text-transform: uppercase;font-weight: bold;border:0;border-bottom: 4px solid #2b7db3;border-radius: 3px;background-color: #3498db;line-height: 36px;padding: 0 18px;width: auto;}
/*Blog*/

/*analytics*/
.analytics .banner{background: url(../images/analytics-bg.jpg) no-repeat center 0;margin-top: -109px;padding-top: 149px;background-size: cover;padding-bottom: 100px;}
.analytics h1{font-size: 34px;color: #fff;font-weight: bold;text-transform: uppercase;margin-bottom: 12px;text-align: center;}
.analytics .sub-head{font-size: 18px;color: #fff;font-weight: 300;text-align: center;}
.line-graph{width: 310px;margin: 60px auto;max-width: 100%;}
.analytics .detail-block {padding: 45px 0 85px 0;clear: both;}
.analytics .detail-block h3{font-size: 34px;text-transform: uppercase;margin-bottom: 10px;}
.analytics .detail-block p{margin-bottom: 30px;font-size: 18px;}
.analytics .detail-block p:last-child{margin-bottom: 0;line-height: 30px;}
.analytics .bar-chart-block {background-color: #3498db;float: left;width: 100%;color: #fff;}
.bar-chart-block .chart-block h3,.bar-chart-block .chart-block p{color: #fff;}
.analytics .bar-graph {margin: 60px auto;display: table;}
.feature-list.dark {background-color: #fafafa;padding: 75px 0 50px 0;}
.feature-list.dark .icon{background: url(../images/analytics-feature-icons.png) no-repeat 0 0;}
.feature-list.dark.segment-feat .icon{background: url(../images/segment-feature-icons.png) no-repeat 0 0;}
.feature-list.dark .icon-2{background-position: -100px 0px!important;}
.feature-list.dark .icon-3{background-position: -212px 0!important;}
.feature-list.dark .icon-4{background-position: -320px 0!important;}
/*analytics*/

/*segment*/
.segment .banner{background: url(../images/analytics-bg.jpg) no-repeat center 0;margin-top: -109px;padding-top: 149px;background-size: cover;padding-bottom: 100px;}
.segment h1{font-size: 34px;color: #fff;font-weight: bold;text-transform: uppercase;margin-bottom: 12px;text-align: center;}
.segment .sub-head{font-size: 18px;color: #fff;font-weight: 300;text-align: center;}
.segment .detail-block {padding: 45px 0 85px 0;clear: both;}
.segment .detail-block h3{font-size: 34px;text-transform: uppercase;margin-bottom: 10px;}
.segment .detail-block p{margin-bottom: 30px;font-size: 18px;}
.segment .detail-block p:last-child{margin-bottom: 0;line-height: 30px;}
.segment .segment-block {background-color: #3498db;float: left;width: 100%;}
.segment-block .detail-block h3,.segment-block .detail-block p{color: #fff;}
.user-segment{margin:130px 0 35px 0;}
.pontential-block{margin: 70px 0 50px;}
/*segment*/
/*login poup*/
.login-popup.register-popup .modal-dialog {width: 392px;}
.login-popup.register-popup .modal-header h4{text-align: left;padding: 6px 0 6px 30px;}
.login-popup.register-popup form .input-group {width: 100%;margin-right: 0;margin-bottom: 18px;}
.login-popup.register-popup form .input-group.last-b{margin-bottom: 10px;}
.login-popup.register-popup .left-block{border-bottom:0;}
.login-popup.register-popup p.text.text-right{text-align: right;}
.login-popup.register-popup .modal-footer .btn{background-image: url(../images/login-social-icn.png);background-repeat: no-repeat;width: 52px;height: 37px;padding: 0;margin-left: 22px;}
.login-popup.register-popup .modal-footer .btn.googleplus{background-color: #db5a45;background-position: center -106px;}
.login-popup.register-popup .modal-footer .btn.facebook{background-position: center 10px;margin-left: 0;}
.login-popup.register-popup .modal-footer .btn.twitter{background-position: center -51px;}
/*login poup*/

/*login page*/
.register-page {margin: 10vh 0;}
.register-page .container{max-width: 295px;}
.register-page .register-popup form .input-group{width: 100%;}
.register-page .register-popup p.text{text-align: right;}
.register-page .register-popup form .input-group button{float: none;}
.register-page .login-popup.register-popup p.text.text-right {margin: 10px 0 20px 0;display: inline-block;width: 100%;}
.register-page .login-popup.register-popup p.text.text-right label{float: left;padding-left: 28px;cursor: pointer;position: relative;-webkit-user-select:none;user-select:none;-moz-user-select:none;}
.register-page .login-popup.register-popup p.text.text-right label input{height: 0;width: 0;}
.register-page .login-popup.register-popup p.text.text-right label .fa{position: absolute;left: 0;top: 5px;color: #3498db;}
.register-page .login-popup.register-popup p.text.text-right label .fa:before{content: "\f096";}
.register-page .login-popup.register-popup p.text.text-right label input:checked + .fa:before{content: "\f046";}
.register-page h1{font-size: 30px;text-align: center;margin-bottom: 30px;}
.register-page.sign_up .container{max-width: 830px;}
.register-page.sign_up .register-popup .right-block p{font-size: 14px;color: #303030;line-height: 26px;margin-bottom: 20px;max-width: 260px;}
.register-page.sign_up .register-popup .modal-body{padding: 0;}
.register-page.sign_up .login-popup.register-popup .left-block{border-bottom: 1px solid #eeeeee;padding-bottom: 10px;}
.register-page.sign_up .register-popup .modal-footer{padding-top: 30px;}
.register-page.sign_up .register-popup .list li{padding-left: 0;}
.register-page.sign_up .register-popup .list li:before{display: none;}
.register-page.sign_up .register-popup .list li .fa{color: #3498db;margin-right: 8px;}
.register-page.sign_up p.footer_text{font-size: 16px;text-align: center;}
.register-page.sign_up .register-popup form .input-group button{margin-top: 15px;}
.register-page.sign_up form{margin-top: 10px;display: inline-block;width: 100%;}
/*login page*/
/*Forgot Password*/
.register-page.forgot_password{text-align: center;}
.register-page.forgot_password .container {max-width: 350px;}
.register-page.forgot_password h1{margin-bottom: 20px;}
.register-page.forgot_password .register-popup form .input-group{margin-bottom: 20px;}
.register-page.forgot_password .register-popup form .input-group button{width: 140px;}
.register-page.forgot_password .register-popup form input.form-control{text-align: center;}
/*Forgot Password*/
/*error_page*/
.error_page{margin:60px 0 60px 0;}
.error_page h1{font-size: 34px;color: #333;font-weight: 300;text-align: center;margin-bottom: 60px;}
.error-body{max-width: 970px;margin: 0 auto;}
.error-body img{float: left;margin-right: 110px;max-width: 100%;}
.error-body .title{font-size: 24px;color: #333;margin-bottom: 15px;}
.error-body p{font-size: 16px;line-height: 24px;color: #333;margin-bottom: 25px;}
.error-body .start{background-color: #3498db;text-align: center;color: #fff;text-transform: uppercase;width: 248px;line-height: 50px;display: inline-block;text-decoration: none;}
.error-body .right-detail{float: left;max-width: 490px;margin-top: 45px;}
/*error_page*/
/*Round about slider*/
#roundaboutslider {overflow:hidden;}
#roundabout-loader {height:380px;margin:50px auto;background:url(images/loading.gif) 50% 230px no-repeat;}
#roundabout-inner {display:none;}
.roundabout-holder {height:380px;left:50%;margin:0;position:absolute;width:610px;margin-left: 4px;max-width: 100%;-webkit-transform: translateX(-50%);-moz-transform: translateX(-50%);-ms-transform: translateX(-50%);}
.roundabout-moveable-item {cursor:pointer;list-style-type:none;overflow:hidden;height:380px;width:606px;margin:0;padding:0;background:none;border-radius:5px 5px 0 0;-moz-border-radius:5px 5px 0 0;-webkit-border-radius:5px 5px 0 0;}
.roundabout-moveable-item.roundabout-in-focus{/*height:100% !important;*/width:100% !important;}
.roundabout-caption {padding:15px;text-align:left;position:absolute;bottom:0;left:0;right:0;background-position:0 bottom;background-repeat:repeat-x;background-color:transparent;z-index:1;font-size:13px;}
.roundabout-caption .roundabout-title {position:relative;display:block;margin:5px 0 10px;font-size:20px;text-shadow:0 0 3px #dddddd;}
.roundabout-caption .roundabout-content {position:relative;display:block;margin-bottom:10px;}
.roundabout-moveable-item img {display:block;height:100%;width:100%;border-radius:5px 5px 0 0;-moz-border-radius:5px 5px 0 0;-webkit-border-radius:5px 5px 0 0;}
.roundabout-in-focus {cursor:auto;z-index:999 !important;}
.roundabout-in-focus a {display:block;height:380px;width:638px;}
#roundabout-prev,#roundabout-next {width:39px;height:39px;display:block;left:50%;top:50%;margin-top:-19px;margin-left:-580px;position:absolute;background:url(images/roundabout-direction.png) 0 0 no-repeat;z-index:999;}
#roundabout-next {background-position:-39px 0;left:auto;right:50%;margin-right:-580px;}
#roundabout-prev:hover {background-position:0 -39px;}
#roundabout-next:hover {background-position:-39px -39px;}
.slider_main{position: relative;}
.slider_main .mac{position: absolute;z-index: 9999;left: 50%;transform: translateX(-50%);-webkit-transform: translateX(-50%);-moz-transform: translateX(-50%);-ms-transform: translateX(-50%);width: 100%;max-width: 819px;}
.slider_main .mac img{max-width: 100%;}
#slider-wrapper{width: 606px;margin: 0 auto;max-width: 100%;}
.roundabout-holder{max-width: 100%;}
.roundabout-list{margin-left: 0 !important;}
.home .container.feauter-block{margin-bottom: 45px;}
/*Round about slider*/
/*support*/
.supports{list-style: none;padding:0;}
.supports li{display: inline-block;margin-right: 15px;}
/*support*/
/*pager*/

.bx-wrapper .bx-pager {text-align: center;font-size: .85em;font-family: Arial;font-weight: bold;color: #666;padding-top: 20px;position: absolute;left: 0;right: 0;bottom: 10px;}
.bx-wrapper .bx-pager .bx-pager-item,.bx-wrapper .bx-controls-auto .bx-controls-auto-item {display: inline-block;*zoom: 1;*display: inline;}
.bx-wrapper .bx-pager.bx-default-pager a {opacity:0.5;background: #fff;text-indent: -9999px;display: block;width: 10px;height: 10px;margin: 0 5px;outline: 0;-moz-border-radius: 5px;-webkit-border-radius: 5px;border-radius: 5px;}
.bx-wrapper .bx-pager.bx-default-pager a:hover,.bx-wrapper .bx-pager.bx-default-pager a.active {background: #3498db;opacity: 1;}
/*pager*/
@media (min-width: 992px){
.navbar-nav>li {margin-left: 5px;margin-right: 5px;}
}
@media (max-width: 1200px){
.blog-main .post-block > div{display: inline-block;width: 100%;}
.blog-main .post-block > div:before{display: none;}
.stats-block-main .container,
.stats-block-main .stats-block,
.testimonial{display: inline-block;box-shadow: none;-moz-box-shadow: none;-webkit-box-shadow: none;width: 100%;font-size: 0;}
.testimonial:before{display: none;}
.testimonial .slider{margin-left: auto;margin-right: auto;float: none;}
.pricing-block-main img.pricing-image{max-width: 100%;}
}

@media (max-width: 991px){
.analytics-block .mac {margin: 0 auto;width: 100%;background-size: contain;max-width: 74%;}
.analytics-block .mac .mac-inner{width: 710px;}
.sub-footer{text-align: center;}
.sub-footer .list li{display: inline-block;margin-right: 20px;}
.sub-footer .title {margin-bottom: 5px;margin-top: 15px;}
.main-sec.home> .banner{margin-top: -150px;}
.navbar .navbar-header{width: 270px;float: none;display: block;margin: auto;}
.main-sec.home> .banner {margin-top: -150px;}
.page-header .header-img{width: 50%;position: static;left: inherit;}
.page-header .container {margin-top: 0;}
.page-header .center-block{margin-bottom: 50px;}
.page-header .controls-block{width: 100%;margin-bottom: 15px;}
.page-header .controls-block form{display: inline-block;vertical-align: bottom;}
.pricing-main .axis-block .x-axis{width: 105%;left: -3%;}
.pricing-main .axis-block .y-axis{height: 105%;bottom: -3%;}
.register-popup .modal-header .close span{top: 6px;right: 6px;}
.register-popup .modal-dialog{width: auto;}
.register-popup .left-block,
.register-popup .right-block{display: inline-block;width: 100%;border: 0;margin-bottom: 20px;padding-left: 0;}
.register-popup form .input-group {width: 100%;}
.register-popup .modal-header h4 {font-size: 26px;}
.register-popup .modal-header .close span:before{font-size: 24px;}
.about-us .banner {margin-top: -150px;padding-bottom: 65px;}
.full-block .row > div:last-child img{position: static;max-width: 100%;margin-bottom: 15px;}
.full-block .row > div:first-child {padding: 30px 15px;}
.clients ul li{width: 33%;}
.clients ul li:last-child{border-right: 2px dashed #ddd;}
.clients ul.bottom li,.clients ul.bottom li:first-child{border-bottom: 2px dashed #ddd;}
.clients ul.bottom li:last-child {border-bottom: 0;}
.blog-main .related-post .img-block{display: inline-block;margin-bottom: 10px;}
.blog-main .related-post .post-con{display: inline-block;width: 100%;}
.blog-main .related-post .post-desc{display: block;}
.segment .banner,
.analytics .banner {margin-top: -150px;padding-bottom: 65px;}
.register-page.sign_up .register-popup .modal-footer{text-align: left;}
.register-page.sign_up p.footer_text,.register-page.sign_up .register-popup .modal-footer .title{text-align: left;}
.error-body img{margin: 0 auto;display: block;float: none;}
.error_page{margin: 40px 0;}
.error_page h1{line-height: normal;text-align: center;margin-bottom: 60px;font-size: 30px;}
.home > .bx-wrapper {margin-top: -150px !important;}
.bx-wrapper .bx-pager{bottom: 30px;}
.main-sec.home ul li .banner{padding-top: 160px;}
.roundabout-moveable-item{width: 100%;min-width: 100%;}
.roundabout-holder{height: auto;}
.roundabout-moveable-item img{height: auto;}
.roundabout-holder,#slider-wrapper{width: 531px !important;height: 360px !important;margin-top: 30px !important;margin-left: 3px !important;}
.slider_main{padding-top: 38px;}
.slider_main .mac{top: 30px;}
.container.slider_block{overflow: hidden;}
.blog-main .post-block > .first img{max-width: 100%;}
.blog-main .post-block > .second i.shape{display: none;}
/*fixes*/
.home > .bx-wrapper {margin-top: 0 !important;}
.feauter-block .head-text .title{padding: 15px 30px 13px 40px;}
.pricing-block-main img.pricing-image{left: 0;}
.register-page.sign_up .register-popup .right-block p{max-width: inherit;}
/*fixes*/
}

@media (max-width: 768px){
.home > .bx-wrapper {margin-top: -120px !important;}
.signup-form form .input-group{float: left;}
.navbar .navbar-header {width: auto;}
header.inverse .navbar-brand, .navbar-brand{width: 190px;background-size: contain;margin-top: 10px;}
.page-header h1 {font-size: 24px;line-height: 35px;}
.navbar .navbar-collapse.in,.navbar .navbar-collapse {background: #3498db;z-index: 9;text-align: center;padding: 0 1vw;margin: 0;overflow: hidden;padding-bottom: 15px;top: 0;border: 0;bottom: 0;position: fixed;transition: all 0.3s;width: 250px;right: -250px;display: block;height: 100% !important;}
.navbar .collapse.in {display: block;right: 0 !important;}
.page-header a.btn.btn-default{margin-bottom: 25px;}
.page-header .controls-block form{width: 100%;max-width: 260px;margin: 0 auto;display: block;padding-bottom: 25px;}
.main-sec h2{font-size: 24px;}
.testimonial {padding-bottom: 50px;}
.testimonial .slider .carousel {height: auto;}
.testimonial .slider{width: 100%;display: inline-block;}
.testimonial .slider .carousel .item{height: auto;}
.testimonial .slider .carousel-caption img{position: static;transform: none;-moz-transform: none;-webkit-transform: none;}
.testimonial .slider{margin: 0;}
.testimonial .slider .carousel-caption{left: 0;position: static;margin-top: 35px}
.testimonial .slider .carousel-caption:before{display: none;}
.testimonial .slider .carousel-indicators{right: 20%;}
.signup-form form .input-group {float: none;margin: 12px auto;}
.analytics-block img {margin: 11px 0 60px 0;}
.logo-list li {display: block;margin: 0 0 15px 0;padding: 0;}
.page-header a.link{vertical-align: top;}
header.inverse .navbar-nav>li>a.btn.btn-blue,
header.inverse .navbar-default .navbar-nav>li>a{color: #fff;}
header .navbar-default .navbar-nav>li>a{display: table;margin: 0 auto;}
.f-navigation{padding: 10px 0 0 0;}
.f-navigation ul.nav-tabs.nav-justified{text-align: center;}
.f-navigation ul.nav-tabs.nav-justified>li{display: inline-block;border: 1px solid #ccc;margin-bottom: 10px;margin-right: 10px;}
.f-navigation ul.nav-tabs li a{margin-bottom: 0;}
.f-navigation ul.nav-tabs li a br{display: none;}
.pricing-block-main img.pricing-image{max-width: 280px;position: static !important;margin-bottom: 15px;}
.pricing-block-main .text-center.pull-right{float: none !important;}
.newsletter{padding: 0 15px;}
.footer .list{margin-left: 0;width: 100%;}
.footer .list li{padding-left: 0;padding-right: 0;}
.footer .list li:first-child {float: left;}
.footer .list li:nth-child(2) {float: right;}
.footer .list li:nth-child(3) {display: block;text-align: center;}
.blog-main .post-block{margin-left: 0;margin-right: 0;}
.c_pricing-block{margin-bottom: 10px;width: 100%;margin-left: 0;}
.c_pricing-block .list{display: inline-block;text-align: center;margin-bottom: 25px;width: 100%;}
.c_pricing-block .list{float: none;display: inline-block;clear: none;margin-right: 20px;}
.c_pricing-block .list li:before {content: '>';margin-right: 5px;}
.pricing-main .axis-block{display: none;}
.logo-block{width: 400px;margin: 0 auto;display: table;}
.logo-block ul{padding: 0;display: inline;}
.clients ul li{width: 180px;display: inline-block;text-align: center;margin: 5px;border: 2px dashed #ddd !important;}
.twitter-feed #tweet li .tweet{width: 90%;}
.logo-block {width: 100%;display: inline-block;padding:0;text-align: center;}
.clients ul li {width: 120px;float: none;vertical-align: top;}
.blog-main .related-post li{float: left;}
.post-type a{margin-bottom: 10px;}
.segment .detail-block h3,
.analytics .detail-block h3 {font-size: 25px;}
.analytics .detail-block {padding: 15px 0 30px 0;}
.analytics .bar-graph {margin: 45px auto;}
.analytics .container{clear: both;}
.segment .detail-block,.pontential-block{float: left;}
.register-page.sign_up .register-popup p.text a{display: inline-block;}
.slider_main .mac{max-width: 268px;}
.roundabout-holder, #slider-wrapper {width: 200px !important;height: 125px !important;margin-left: 1px !important;margin-right: 0 !important;}
.roundabout-moveable-item{top: -30px !important;}
.navbar-nav>li>a.btn.btn-blue {background-color: #0959b9;}
.bx-wrapper .bx-pager{bottom: 10px;}
}